import commonConfig from "./common.config";

export default {
  ...commonConfig,
  onecarePushkey: 'BM3MxIRu28xJIcIs76BlsH7cPM17pBZwMMAcvq1gQMGgaDnA3PzZ5vgndhNtoFQPIon__LFTQqRF80Z6AHNjGzs',
  vikandDirectPushkey: 'BCC9TlmesdLmBIYUUYhhu8Df0angc2g6tf8zY9BlUnvQavMYrrAp9ZnsKz_UzQ-aC1_EOEtg4JQMqjNPuKc-t-g',
  onshipPushkey: 'BPpdtKdM-aTcVL4i2Z5ccHeOnLJbZaozItAv2UR0_C08r_kLVswJVo5W1HYat3Kl58PLtRgwhSEcEB8Fubv2T2M',
  stationonePushKey: 'BMtc1kZ8zIV8TQ0zjquiuC4iTUTFy-CntmB-OxH64jW_oolKEItsk-y87B0dFBXDv0ViXW3hKL07uOyn5VzCxbg',
  vikandTestPushkey: 'BB-4KG_6eHYLLLnaamkGXntHCLuPUC1jh5Iz2CRIJtBVN1T9StH89ZgKr7bUnR3BrFrsGVDWpESOWdFbk-yeSFk',
  bemPushKey: 'BDbqwDhV7Ol1EL-UGuMCjLL7OB2wV4W19bqUStbeF2wReCsQF_bEJDmg25GCIMg10QYpMj2zX9wu-YGlrW_xHeY',
  onshipWebPushAppType: "onship",
  vikandWebPushAppType: "vikand",
  onecareWebPushAppType: "onecare",
  stationoneWebPushAppType: "stationone_prod",
  vikandTestWebPushAppType: "vikand_test",
  bemWebPushAppType: "bem",
  dailyPlatformUrl: 'https://frontm.daily.co/',
  dailyRedirectUrl:'https://dailydev.frontm.ai/',
  daily_baseUrl:'https://y7uq3an27c.execute-api.us-east-1.amazonaws.com/item/video',
  xApiKey:'PUQmy8zhBD6ER7WDevZZJ1KU2BPhntOM90jhLJEO',
  licenseCodeVerificationAPI: 'https://w6k3jxe1yi.execute-api.us-east-1.amazonaws.com/stage',
  licenseCodeVerificationAPI_Token: '2Q5WfwK4lp9WaHgHa7IFR6fUSAntswKdazEPCNhW',
};
