import { SHOW_LOADER, HIDE_LOADER, BOT_WAIT_LOADER, SHOW_FAILED_LOADER, HIDE_FAILED_LOADER } from "../actions/loader";

let initialState = {
	process: [],
	failedProcess: false,
	botWaitLoader: false,
};

function loaderReducer(state = initialState, action) {
	switch (action.type) {
		case SHOW_LOADER:
		case HIDE_LOADER:
		case BOT_WAIT_LOADER:
			return {
				...state,
				...action.data,
			};
		case SHOW_FAILED_LOADER:
		case HIDE_FAILED_LOADER:
			return {
				...state,
				...action.data,
			}	

		default:
			return state;
	}
}

export default loaderReducer;
